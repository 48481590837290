import gql from 'graphql-tag';

export const GET_AGENCY = gql`
  query Agency($id: ID!) {
    agencyLegacy(id: $id) {
      id
      altId
      name
      externalId
      externalSystemId
      status
      territory
      advertisers {
        id
        name
        altId
        domains
        status
      }
    }
  }
`;

export const GET_AGENCY_MINIMAL = gql`
  query AgencyMinimal($id: ID!) {
    agencyLegacy(id: $id) {
      id
      name
      territory
    }
  }
`;

export const GET_ALL_AGENCIES = gql`
  query AllAgencies($territories: [Territory!]!) {
    allAgenciesLegacy(territories: $territories) {
      id
      altId
      name
      status
      externalId
      externalSystemId
    }
  }
`;

export const GET_ALL_AGENCIES_MINIMAL = gql`
  query AllAgenciesMinimal($territories: [Territory!]!) {
    allAgenciesLegacy(territories: $territories) {
      id
      name
    }
  }
`;
