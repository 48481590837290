import Container from 'components/Container/Container';
import Loader from 'components/Loader/Loader';
import Redirecting from 'components/Redirecting/Redirecting';
import UpdateAgencyContainer from 'features/direct/agency/containers/UpdateAgencyContainer/UpdateAgencyContainer';
import { History } from 'history';
import {
  Agency,
  PermissionName,
  useAgencyQuery,
} from 'interfaces/generated.types';
import React from 'react';
import { userHasAtLeastOnePermission } from 'utils/permissions';

interface UpdateAgencyPageProps {
  history: History;
  match: {
    params: {
      agencyId: string;
    };
  };
  userPermissions: PermissionName[];
}

const editPermissions = [PermissionName.Primary, PermissionName.Direct];

const UpdateAgencyPage = ({
  history,
  match,
  userPermissions,
}: UpdateAgencyPageProps) => {
  const { loading, data } = useAgencyQuery({
    errorPolicy: 'all',
    variables: { id: match.params.agencyId },
  });

  if (!loading && !data?.agencyLegacy) return <Redirecting history={history} />;

  const hasEditPermissions = userHasAtLeastOnePermission(
    userPermissions,
    editPermissions
  );

  return (
    <main>
      <Container>
        <h1 className="sr-only">Update Agency</h1>
        {loading ? (
          <Loader />
        ) : (
          <UpdateAgencyContainer
            history={history}
            agency={data?.agencyLegacy as Agency}
            hasEditPermissions={hasEditPermissions}
            match={match}
          />
        )}
      </Container>
    </main>
  );
};

export default UpdateAgencyPage;
